import dayjs from 'dayjs';
import notify from 'devextreme/ui/notify';
import { usePage } from '../components';
import { API, ROLES } from '../constants/api';
import { useAppDataSource, useFindById, useProfile } from './store';
import Geocode from 'react-geocode';
import { Button } from 'devextreme-react';

export const useIsAdmin = () => {
  const profile = useProfile();

  return [ROLES.ADMIN, ROLES.S_ADMIN].includes(profile.role);
};

export const keyBy = (data = [], key) => {
  const result = {};
  if (Array.isArray(data)) {
    data.forEach((x) => {
      result[x[key]] = x;
    });
  } else {
    result[data[key]] = data;
  }

  return result;
};

export const dateString = (date) => dayjs(date).format('DD/MM/YYYY');

export const useFilterBySearch = (dataSource = []) => {
  const { searchValue = '' } = usePage();

  const value = searchValue?.toLowerCase();

  return dataSource.filter((x) => {
    return Object.values(x).find((x) => typeof x === 'string' && x.toLowerCase().includes(value));
  });
};

export const notification = (message, type, duration = 3) => {
  notify(
    {
      message,
      width: 'auto',
      animation: {
        show: { type: 'fade', duration: 400, from: 0, to: 1 },
        hide: { type: 'fade', duration: 400, from: 1, to: 0 },
      },
      closeOnClick: true,
      position: { at: 'center top', offset: { x: 0, y: 100 } },
      type,
      displayTime: duration * 1000,
    },
    {
      position: 'top center',
      direction: 'down-stack',
    }
  );
};

export const readFileAsDataURL = async (file) => {
  let result_base64 = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });

  return result_base64;
};

export const useCSV = () => {
  const findById = useFindById();
  const tags = useAppDataSource(API.TAG).flatMap(({ subTags, ...tag }) => [tag, ...subTags]);

  const getCSV = (dataSource, fields = []) => {
    const lines = dataSource.map((obj) => {
      const line = fields
        .map((dataField) => {
          let value = obj[dataField];
          if (dataField === 'document') {
            value = typeof value === 'object' ? value.url : value;
          }
          if (dataField === 'user') {
            const user = findById(API.USER, value);
            value = `${user?.name ?? '???'}${user?.surname ? ` ${user.surname}` : ''}`;
          }
          if (dataField === 'project') {
            const project = findById(API.PROJECT, value);
            value = project?.description ?? '???';
          }
          if (dataField === 'expenseSheet') {
            const expenseSheet = findById(API.EXPENSE_SHEET, value);
            value = expenseSheet?.description ?? '???';
          }
          if (dataField === 'currency') {
            const code = findById(API.CURRENCY_CODE, value);
            value = code?.description ?? '???';
          }
          if (dataField === 'tag' || dataField === 'subTag') {
            const expenseSheet = tags.find((x) => x._id === value);
            value = expenseSheet?.description ?? '???';
          }
          if (dataField === 'paymentMethod') {
            const method = findById(API.PAYMENT_METHOD, value);
            value = method?.description ?? '???';
          }
          if (dataField === 'business') {
            const client = findById(API.BUSINESS, value);
            value = client?.name ?? '???';
          }
          if (['startRoute', 'endRoute'].includes(dataField)) {
            value = value?.split('$$')[1];
          }
          if (['startDate', 'endDate', 'start', 'end', 'planeStart', 'planeEnd', 'date'].includes(dataField)) {
            value = dateString(value);
          }
          return typeof value === 'string' ? value.replace(/[\n,;]/g, ' ') : value ?? '???';
        })
        .join(',');
      return line;
    });

    return [fields.join(','), ...lines].join('\n');
  };

  return { getCSV };
};

export const getLoc = async (latitude, longitude) => {
  return new Promise((resolve, reject) => {
    Geocode.fromLatLng(latitude, longitude).then(
      ({ results }) => {
        const [{ address_components }] = results;
        const [number = '', street = '', town = '', , region = '', country = '', postal_code = ''] =
          address_components.map((x) => x.long_name);

        const newAddress = street ? `${street}, ${number}, ${postal_code} (${town} - ${region}, ${country})` : '???';

        const localization = `${latitude},${longitude}$$${newAddress}`;
        resolve(localization);
      },
      (error) => {
        const localization = `${latitude},${longitude}$$???`;
        resolve(localization);
      }
    );
  });
};

export const usePagination = (items = []) => {
  const { currentPage, pageSize, setCurrentPage } = usePage();
  const skip = currentPage * pageSize;
  const end = pageSize ? skip + pageSize : undefined;
  const pageItems = items.slice(skip, end);

  const Pagination = () => {
    return (
      <div
        style={{
          display: 'flex',
          gap: 8,
          justifyContent: 'space-between',
          marginTop: 'auto',
          alignItems: 'center',
          padding: '4px 8px 0 8px',
        }}
      >
        <Button
          icon="chevronleft"
          text="Anterior"
          disabled={!currentPage}
          onClick={() => setCurrentPage((prev) => (prev -= 1))}
        />
        {currentPage + 1}
        <Button
          icon="chevronright"
          text="Siguiente"
          onClick={() => setCurrentPage((prev) => (prev += 1))}
          disabled={pageSize === 0 || !Boolean(pageItems.length) || items.length <= end}
        />
      </div>
    );
  };
  return { pageItems, Pagination };
};

export const Tag = ({ text, color, position = 'right' }) => {
  return (
    <div
      className={`tag ${position}`}
      style={{
        color: 'white',
        backgroundColor: color,
      }}
    >
      {text}
    </div>
  );
};

export const RateRender = (rate) => {
  return {
    2: 'smile.svg',
    1: 'normal.svg',
    0: 'sad.svg',
  }[rate];
};
