import { Button, RadioGroup, TagBox } from 'devextreme-react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { usePage } from '..';
import { API } from '../../constants/api';
import { useAppDataSource } from '../../utils/store';

export const Filter = () => {
  const { pathname } = useLocation();

  const { filterVisible, setFilterVisible, filterData, setFilterData } = usePage();

  const tags = useAppDataSource(API.TAG).flatMap(({ subTags = [], ...tag }) => [tag, ...subTags]);
  const expenseSheets = useAppDataSource(API.EXPENSE_SHEET);
  const users = useAppDataSource(API.USER);

  if (!filterVisible) {
    return null;
  }

  const handleChange = (key) => (value) => {
    const data = { ...filterData };
    data[key] = value;

    setFilterData(data);
  };

  const handleClear = () => {
    setFilterData({});
  };

  const handleClose = () => {
    setFilterVisible(false);
  };

  return (
    <Wrapper>
      <div className="header">
        <div>Filtros</div>
        <div style={{ display: 'flex', gap: 8 }}>
          <Button icon="clearformat" onClick={handleClear} />
          <Button icon="close" onClick={handleClose} />
        </div>
      </div>
      {pathname === '/expenses' ? (
        <>
          <TagBox
            value={filterData?.type}
            onValueChange={handleChange('type')}
            searchEnabled={true}
            label="Tipo"
            dataSource={[
              {
                label: 'Ticket',
                value: 'TICKET',
              },
              {
                label: 'Factura',
                value: 'INVOICE',
              },
              {
                label: 'Kilometraje',
                value: 'MILEAGE',
              },
              {
                label: 'Dieta',
                value: 'DIET',
              },
            ]}
            valueExpr={'value'}
            displayExpr="label"
          />
          <TagBox
            value={filterData?.tags}
            onValueChange={handleChange('tags')}
            label="Tags"
            searchEnabled={true}
            dataSource={{
              store: tags,
              pageSize: 10,
              paginate: true,
            }}
            valueExpr={'_id'}
            displayExpr="description"
          />
          <TagBox
            value={filterData?.expenseSheets}
            onValueChange={handleChange('expenseSheets')}
            label="Hojas de gastos"
            searchEnabled={true}
            dataSource={{
              store: expenseSheets,
              pageSize: 10,
              paginate: true,
            }}
            valueExpr={'_id'}
            displayExpr="description"
          />
        </>
      ) : null}
      {pathname === '/expensesheets' ? (
        <>
          <RadioGroup
            value={filterData?.closed}
            onValueChange={handleChange('closed')}
            dataSource={[
              { value: true, label: 'Cerrada' },
              { value: false, label: 'Abierta' },
              { value: null, label: 'Todas' },
            ]}
            valueExpr="value"
            displayExpr={'label'}
            layout="horizontal"
          />
        </>
      ) : null}
      {pathname === '/travels' || pathname === '/holidays' ? (
        <>
          <RadioGroup
            defaultValue={null}
            value={filterData?.state}
            onValueChange={handleChange('state')}
            dataSource={[
              { value: 'ACCEPTED', label: 'Aceptados' },
              { value: 'PENDING', label: 'Pendientes' },
              { value: 'REJECTED', label: 'Denegados' },
              { value: 'CANCELED', label: 'Cancelados' },
              { value: null, label: 'Todos' },
            ]}
            valueExpr="value"
            displayExpr={'label'}
            layout="horizontal"
          />
        </>
      ) : null}

      {pathname === '/travelcontrol' || pathname === '/holidaycontrol' ? (
        <>
          <RadioGroup
            defaultValue={null}
            value={filterData?.state}
            onValueChange={handleChange('state')}
            dataSource={[
              { value: 'ACCEPTED', label: 'Aceptados' },
              { value: 'PENDING', label: 'Pendientes' },
              { value: 'REJECTED', label: 'Denegados' },
              { value: 'CANCELED', label: 'Cancelados' },
              { value: null, label: 'Todos' },
            ]}
            valueExpr="value"
            displayExpr={'label'}
            layout="horizontal"
          />
          <TagBox
            label="Usuarios"
            onValueChange={handleChange('users')}
            searchEnabled={true}
            showSelectionControls={true}
            maxDisplayedTags={3}
            dataSource={{
              store: users,
              pageSize: 10,
              paginate: true,
            }}
            valueExpr="_id"
            displayExpr={(item) => `${item.name} ${item.surname}`}
          />
        </>
      ) : null}
      {pathname === '/signups' || pathname === '/accesscontrol' ? (
        <>
          <RadioGroup
            defaultValue={null}
            value={filterData?.state}
            onValueChange={handleChange('state')}
            dataSource={[
              { value: 'DONE', label: 'Fichados' },
              { value: 'PENDING', label: 'Pendientes' },
              { value: null, label: 'Todos' },
            ]}
            valueExpr="value"
            displayExpr={'label'}
            layout="horizontal"
          />
        </>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
