import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import { GOOGLE_API_KEY } from './constants/api';
import { ROUTES } from './constants/routes';
import LoginPage from './pages/Login';
import { useAppStore, useProfile } from './utils/store';
import Geocode from 'react-geocode';

function App() {
  const isDataLoaded = useAppStore((s) => s.isDataLoaded);
  const profile = useProfile();

  return (
    <BrowserRouter>
      <MainLayout>
        <Suspense>
          <Routes>
            {profile && isDataLoaded ? (
              <>
                {Object.entries(ROUTES)
                  .filter(([key, { roles }]) => {
                    return roles.includes(profile.role);
                  })
                  .map(([path, { Page, form }]) => (
                    <Route key={path} path={path} element={<Page objectType={form} />} />
                  ))}
                <Route path="/*" element={<Navigate to="/expenses" />} />
              </>
            ) : (
              <>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/*" element={<Navigate to="/login" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage('es');
Geocode.setRegion('es');
