import { useEffect } from 'react';
import { API } from '../constants/api';
import { useAppStore } from '../utils/store';
import axios from 'axios';

const DataLoader = () => {
  const set = useAppStore((s) => s.set);
  const setupSocket = useAppStore((s) => s.setupSocket);

  useEffect(() => {
    const fetchData = async () => {
      const entries = await Promise.all(
        TO_FETCH.map(async (key) => {
          const data = await axios
            .get(`/${key}`)
            .then((x) => x.data)
            .catch(() => null);

          return [key, data];
        })
      );

      set({ ...Object.fromEntries(entries), isDataLoaded: true });
      setupSocket(entries[0][1]);
    };

    fetchData();
  }, [set, setupSocket]);

  return <></>;
};

export default DataLoader;

const TO_FETCH = [
  API.PROFILE,
  API.EXPENSE,
  API.EXPENSE_SHEET,
  API.PROJECT,
  API.TAG,
  API.USER,
  API.BUSINESS,
  API.PAYMENT_METHOD,
  API.CURRENCY_CODE,
  API.SIGN_UP,
  API.HOLIDAY,
  API.TRAVEL,
  API.ESTABLISHMENT,
  API.ESTABLISHMENT_INFO,
  API.ESTABLISHMENT_SETTINGS,
  API.ROUTE,
  API.SUPERVISOR,
  API.TASK,
  API.COUNTRIES,
  API.GROUP,
  API.COMPANY,
  API.PROVIDER,
];
