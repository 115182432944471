import axios from 'axios';
import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './index.css';
import 'dayjs/locale/es';
import { BASE_URL } from './constants/api';
import { notification } from './utils';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

axios.defaults.baseURL = BASE_URL;
dayjs.locale('es');

axios.interceptors.response.use(undefined, (err) => {
  notification(err?.response?.data ?? 'Ha ocurrido un error', 'warning');
  return err;
});
