import dayjs from 'dayjs';
import { Drawer, List } from 'devextreme-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { PageContext } from '..';
import { API } from '../../constants/api';
import { ROUTES } from '../../constants/routes';
import { useAppStore, useProfile } from '../../utils/store';
import { Header } from './Header';
import { Filter } from '../Filter';
import { getPathMonthDateRange } from '../../constants/other';

const MainLayout = ({ children }) => {
  const { pathname } = useLocation();

  const getKey = useCallback((key) => localStorage.getItem(`${pathname}.${key}`), [pathname]);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [sort, setSort] = useState(false);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [filterVisible, setFilterVisible] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [pageSize, setPageSize] = useState(JSON.parse(getKey('pageSize')));
  const [currentPage, setCurrentPage] = useState(0);

  const appMenu = useAppStore((s) => s.appMenu);
  const set = useAppStore((s) => s.set);

  const settings = useAppStore((s) => s[API.ESTABLISHMENT_SETTINGS]);

  useEffect(() => {
    document.querySelector(':root').style.setProperty('--primary-color', settings?.establishmentISO.primaryColor);
  }, [settings?.establishmentISO.primaryColor]);

  const setKey = (key, value) => localStorage.setItem(`${pathname}.${key}`, value);

  useEffect(() => {
    setStartDate(dayjs().subtract(getPathMonthDateRange(pathname), 'month'));
    setEndDate(dayjs());
    setSort(false);
    setSearchEnabled(false);
    setSearchValue('');

    setFilterVisible(false);
    setFilterData({});
    setPageSize(Number(JSON.parse(getKey('pageSize')) ?? 10));
  }, [getKey, pathname]);

  return (
    <PageContext.Provider
      value={{
        getKey,
        setKey,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        sort,
        setSort,
        searchValue,
        setSearchValue,
        searchEnabled,
        setSearchEnabled,
        filterVisible,
        setFilterVisible,
        filterData,
        setFilterData,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
      }}
    >
      <Drawer
        opened={appMenu}
        openedStateMode="overlap"
        shading={true}
        width={'100vw'}
        component={NavigationList}
        height="100vh"
        closeOnOutsideClick={true}
        onOpenedChange={(value) => set({ appMenu: value })}
      >
        <Header />
        <main id="main-content">
          {children}
          <Filter />
        </main>
      </Drawer>
    </PageContext.Provider>
  );
};

export default MainLayout;

const APP_VERSION = process.env.REACT_APP_VERSION;

const NavigationList = () => {
  const listRef = useRef();
  const [items, setItems] = useState([]);
  const set = useAppStore((s) => s.set);
  const settings = useAppStore((s) => s[API.ESTABLISHMENT_SETTINGS]);
  const navigate = useNavigate();
  const profile = useProfile();

  const { establishmentISO } = settings ?? {};

  const handleClickItem = (item) => {
    navigate(item.itemData.path);
    set({ appMenu: false });
  };

  useEffect(() => {
    const items = {};
    const rest = [];

    Object.entries(ROUTES)
      .filter(([, x]) => !x.hidden && x.roles.includes(profile?.role))
      .forEach(([path, { title: text, icon, key }]) => {
        if (!items[key]) {
          items[key] = [];
        }
        if (!key) {
          rest.push({ path, text, icon });
          return;
        }
        items[key].push({ path, text, icon });
      });
    const menuItems = Object.entries(items).map(([key, items]) => ({ key, items }));

    setItems(menuItems);
  }, [profile?.role]);

  return (
    <NavigationWrapper style={{ backgroundColor: establishmentISO?.backgroundColor }}>
      <img width={'100%'} src={settings?.establishmentISO?.logo} style={{ marginBottom: 8 }} alt="" />
      <div style={{ textAlign: 'right', color: '#fff' }}>v{APP_VERSION}</div>
      <List
        ref={listRef}
        dataSource={items}
        grouped={true}
        collapsibleGroups={true}
        style={{ color: 'white', height: '100%' }}
        hoverStateEnabled={false}
        activeStateEnabled={false}
        focusStateEnabled={false}
        onItemClick={handleClickItem}
      />
    </NavigationWrapper>
  );
};

const NavigationWrapper = styled.div`
  padding: 8px;
  overflow: hidden;
  width: 200px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .dx-item,
  .dx-icon {
    color: white !important;
  }

  .dx-list-item {
    border-top: 0;
    border-bottom: 1px solid #777;
  }

  .dx-list-group-header {
    background: inherit;
    color: white;
  }
`;
