import { ActionSheet, Button, DropDownButton } from 'devextreme-react';
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePage } from '..';
import { API } from '../../constants/api';
import { ROUTES } from '../../constants/routes';
import { useAppStore, useProfile } from '../../utils/store';
import { SearchBar } from './SearchBar';

export const Header = () => {
  const { establishmentISO } = useAppStore((s) => s[API.ESTABLISHMENT_SETTINGS] ?? {});
  const set = useAppStore((s) => s.set);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const profile = useProfile();
  // const get = useAppStore((s) => s.get);

  const actionsRef = useRef();
  const { setKey, setFilterVisible, searchEnabled, setSearchEnabled, pageSize, setPageSize, setCurrentPage } =
    usePage();

  const isInForm = pathname.match(/(\/\w{24}|\/new)$/);
  const path = pathname.toLowerCase();
  const basePath = path.replace(isInForm?.[0], '');

  const currentRoute = isInForm ? ROUTES[`${basePath}/:id`] : ROUTES[path];

  const isBlacklisted = blacklist.includes(path);
  // const { getCSV } = useCSV();

  return (
    <HeaderWrapper
      style={{ backgroundColor: establishmentISO?.backgroundColor, display: profile ? undefined : 'none' }}
    >
      <Button icon="back" visible={Boolean(isInForm)} onClick={() => navigate(-1)} />
      <Button icon="menu" visible={!Boolean(isInForm)} onClick={() => set((prev) => ({ appMenu: !prev.appMenu }))} />
      <SearchBar isInForm={isInForm || isBlacklisted} />
      {!searchEnabled || Boolean(isInForm) ? (
        <>
          <div style={{ flexGrow: 1, textAlign: 'left' }}>{currentRoute?.title}</div>
          <DropDownButton
            visible={['/expenses', '/expensesheets', '/signups', '/holidays', '/travels'].includes(pathname)}
            width={75}
            stylingMode="text"
            style={{ color: 'white' }}
            text={`${!isNaN(pageSize) ? pageSize || 'Todo' : 'Todo'}`}
            onItemClick={(e) => {
              setKey('pageSize', e.itemData.value);
              setPageSize(e.itemData.value);
              setCurrentPage(0);
            }}
            items={[
              { text: 'Todo', value: 0 },
              { text: '10', value: 10 },
              { text: '25', value: 25 },
              { text: '50', value: 50 },
            ]}
          />
        </>
      ) : null}
      <div className="flex">
        <Button
          icon={searchEnabled ? 'close' : 'search'}
          visible={!Boolean(isInForm) && !isBlacklisted}
          onClick={() => {
            setSearchEnabled(!searchEnabled);
          }}
        />
        <Button
          icon="overflow"
          visible={!Boolean(isInForm) && !isBlacklisted}
          onClick={() => actionsRef.current.instance.option('visible', true)}
        />
      </div>
      <ActionSheet
        ref={actionsRef}
        title={currentRoute?.title}
        dataSource={[
          { text: 'Crear', onClick: () => navigate(`${basePath}/new`) },
          // {
          //   text: 'Descargar CSV',
          //   onClick: () => {
          //     const form = '';
          //     const dataSource = get()[form];
          //     const fields = []; // TODO Get all form fields

          //     const data = getCSV(dataSource, fields);
          //     const blob = new Blob([data], { type: 'text/csv' });
          //     const url = window.URL.createObjectURL(blob);
          //     var link = document.createElement('a');
          //     link.setAttribute('href', url);
          //     link.setAttribute('download', 'my_data.csv');
          //     document.body.appendChild(link);
          //     link.click();

          //     document.body.removeChild(link);
          //   },
          // },
          {
            visible: !filterBlacklist.includes(pathname),
            text: 'Filtrar',
            onClick: () => {
              setFilterVisible(true);
            },
          },
        ]}
        onItemClick={() => actionsRef.current.instance.option('visible', false)}
        cancelText="Cancelar"
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  padding: 0 8px;
  gap: 8px;
  color: #fff;
  align-items: center;
  min-height: 48px;
  max-height: 48px;

  h1 {
    font-size: 18px;
    text-transform: uppercase;
  }

  a {
    display: grid;
    place-content: center;
  }

  .flex {
    display: flex;
    gap: 8px;
  }

  .dx-widget {
    background: inherit;
    border: 0;
    i,
    input {
      color: white;
    }
  }

  .dx-dropdownbutton .dx-button-text {
    color: white;
  }
`;

const blacklist = ['/profile', '/establishment'];

const filterBlacklist = ['/projects', '/paymentmethods', '/business', '/tags', '/currencycodes'];
