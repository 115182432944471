import { lazy } from 'react';
import { API, ROLES } from './api';

const ADMIN = [ROLES.ADMIN, ROLES.S_ADMIN];
const ALL = [ROLES.ADMIN, ROLES.S_ADMIN, ROLES.USER];

export const ROUTES = {
  '/expenses': {
    title: 'Mis gastos',
    Page: lazy(() => import('../pages/Expenses')),
    roles: ALL,
    icon: 'fa-solid fa-receipt',
    key: 'General',
  },
  '/expenses/:id': {
    title: 'Gasto',
    form: API.EXPENSE,
    Page: lazy(() => import('../components/AppForm/ExpenseForm')),
    hidden: true,
    roles: ALL,
  },
  '/expensesheets': {
    title: 'Mis hojas de gastos',
    Page: lazy(() => import('../pages/ExpenseSheets')),
    roles: ALL,
    icon: 'fa-solid fa-file-invoice-dollar',
    key: 'General',
  },
  '/expensesheets/:id': {
    title: 'Hoja de gastos',
    form: API.EXPENSE_SHEET,
    Page: lazy(() => import('../components/AppForm/ExpenseSheetForm')),
    hidden: true,
    roles: ALL,
  },
  '/rejectedexpensesheets': {
    title: 'Hojas rechazadas',
    Page: lazy(() => import('../pages/RejectedExpenseSheets')),
    roles: ALL,
    icon: 'fa-solid fa-file-circle-xmark',
    key: 'General',
  },
  '/rejectedexpensesheets/:id': {
    title: 'Hoja rechazadas',
    form: API.EXPENSE_SHEET,
    Page: lazy(() => import('../components/AppForm/ExpenseSheetForm')),
    hidden: true,
    roles: ALL,
  },
  '/signups': {
    title: 'Mis accesos',
    Page: lazy(() => import('../pages/SignUps')),
    roles: ALL,
    icon: 'fa-solid fa-users',
    key: 'General',
  },
  '/signups/:id': {
    title: 'Acceso',
    form: API.SIGN_UP,
    Page: lazy(() => import('../components/AppForm/SignUpForm')),
    hidden: true,
    roles: ALL,
  },
  '/holidays': {
    title: 'Mis vacaciones',
    Page: lazy(() => import('../pages/Holidays')),
    roles: ALL,
    icon: 'fa-solid fa-mug-hot',
    key: 'General',
  },
  '/holidays/:id': {
    title: 'Vacación',
    form: API.HOLIDAY,
    Page: lazy(() => import('../components/AppForm/HolidayForm')),
    hidden: true,
    roles: ALL,
  },
  '/travels': {
    title: 'Mis viajes',
    Page: lazy(() => import('../pages/Travels')),
    roles: ALL,
    icon: 'fa-solid fa-plane',
    key: 'General',
  },
  '/travels/:id': {
    title: 'Petición de viaje',
    form: API.TRAVEL,
    Page: lazy(() => import('../components/AppForm/TravelForm')),
    hidden: true,
    roles: ALL,
  },
  '/signup': {
    title: 'Fichar',
    Page: lazy(() => import('../pages/SignUp')),
    roles: ALL,
    icon: 'fa-solid fa-right-to-bracket',
    key: 'General',
  },
  '/holidaycontrol': {
    title: 'Control de vacaciones',
    Page: lazy(() => import('../pages/HolidaysControl')),
    roles: ADMIN,
    icon: 'fa-solid fa-mug-hot',
    key: 'Administración',
  },
  '/holidaycontrol/:id': {
    title: 'Vacación',
    Page: lazy(() => import('../components/AppForm/HolidayForm')),
    form: API.HOLIDAY,
    hidden: true,
    roles: ADMIN,
  },
  '/travelcontrol': {
    title: 'Control de viajes',
    Page: lazy(() => import('../pages/TravelsControl')),
    roles: ADMIN,
    icon: 'fa-solid fa-plane',
    key: 'Administración',
  },
  '/travelcontrol/:id': {
    title: 'Viaje',
    Page: lazy(() => import('../components/AppForm/TravelForm')),
    form: API.TRAVEL,
    hidden: true,
    roles: ADMIN,
  },
  '/accesscontrol': {
    title: 'Control de accesos',
    Page: lazy(() => import('../pages/AccessControl')),
    roles: ADMIN,
    icon: 'fa-solid fa-users',
    key: 'Administración',
  },
  '/profile': {
    title: 'Mi perfil',
    Page: lazy(() => import('../pages/Profile')),
    roles: ALL,
    icon: 'fa-solid fa-user',
    key: 'Otros',
  },
  '/logout': {
    title: 'Cerrar sesión',
    Page: lazy(() => import('../pages/Logout')),
    roles: ALL,
    icon: 'fa-solid fa-person-running',
    key: 'Otros',
  },
};
