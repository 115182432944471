import dayjs from 'dayjs';
import { Button, DateBox } from 'devextreme-react';
import { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { getPathMonthDateRange } from '../constants/other';

export const PageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  & > section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    padding-bottom: 64px;
    padding-top: 8px;
    overflow: hidden auto;
    padding: 8px;

    & > * {
      flex-basis: 400px;
    }
  }

  a {
    text-decoration: none;
    color: unset;
  }
`;

export const Empty = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>No hay datos</div>
  );
};

const getStoredDate = (dateKey) => sessionStorage.getItem(dateKey) ?? dayjs();

export const useDateNavigation = (dateKey) => {
  const { pathname } = useLocation();
  const { startDate, setStartDate, endDate, setEndDate, sort, setSort } = usePage();

  const [currentDate, setCurrentDate] = useState(dayjs(getStoredDate(dateKey)));

  const handleChangeDate = (value) => {
    localStorage.setItem(dateKey, value.toDate());
    setCurrentDate(value);
  };

  const handleChangeStart = (value) => {
    if (!value) {
      value = dayjs().subtract(getPathMonthDateRange(pathname), 'month');
    } else {
      value = dayjs(value);
    }
    setStartDate(value);
  };

  const handleChangeEnd = (value) => {
    value = dayjs(value ?? undefined);
    setEndDate(value);
  };

  const DateNavigation = () => {
    return (
      <Footer>
        <Button icon="chevronleft" onClick={() => handleChangeDate(currentDate.subtract(1, 'day'))} />
        <DateBox
          max={dayjs()}
          value={currentDate.toDate()}
          style={{ margin: '0 auto' }}
          displayFormat="dd/MM/yyyy"
          onValueChange={(value) => handleChangeDate(dayjs(value))}
        />
        <Button
          icon="chevronright"
          disabled={currentDate.isSame(dayjs(), 'date')}
          onClick={() => handleChangeDate(currentDate.add(1, 'day'))}
        />
      </Footer>
    );
  };
  const RangeDateNavigation = () => {
    const handleSort = () => setSort((prev) => !prev);

    return (
      <Footer style={{ justifyContent: 'space-between' }}>
        <DateBox
          max={dayjs()}
          value={startDate.toDate()}
          style={{ maxWidth: 144 }}
          displayFormat="dd/MM/yyyy"
          onValueChange={handleChangeStart}
          showClearButton={true}
        />
        <Button icon={sort ? 'sortup' : 'sortdown'} onClick={handleSort} />
        <DateBox
          min={startDate}
          value={endDate.toDate()}
          style={{ maxWidth: 144 }}
          displayFormat="dd/MM/yyyy"
          onValueChange={handleChangeEnd}
          showClearButton={true}
        />
      </Footer>
    );
  };

  return { DateNavigation, RangeDateNavigation, currentDate, startDate, endDate, sort };
};

const Footer = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;

  .dx-datebox {
    flex-grow: 1;
  }
`;

export const AppLogo = ({ white }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '3rem',
        fontWeight: 600,
        marginBottom: 16,
        color: white ? 'white' : undefined,
      }}
    >
      <img src="/logo192.png" width={64} height={64} alt="" />
      <span>wark</span>
    </div>
  );
};

export const PageContext = createContext({
  filter: {
    visible: false,
    data: null,
  },
  startDate: null,
  endDate: null,
  pageSize: 25,
});

export const usePage = () => useContext(PageContext);
