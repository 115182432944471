import axios from 'axios';
import { Form, LoadPanel } from 'devextreme-react';
import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AppLogo } from '../components';
import DataLoader from '../components/DataLoader';
import { API } from '../constants/api';
import { notification } from '../utils';
import { useAppStore, useProfile } from '../utils/store';

const LoginPage = () => {
  const loadingRef = useRef();
  const profile = useProfile();
  const set = useAppStore((s) => s.set);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = Object.fromEntries([...new FormData(e.target).entries()]);

    login(data);
  };

  const login = useCallback(
    (credentials) => {
      loadingRef.current.instance.option('visible', true);
      axios
        .post('user/login', credentials)
        .then(({ data }) => {
          set({ [API.PROFILE]: data });
          axios.defaults.headers = {
            Authorization: `Bearer ${data.access_token}`,
          };

          if (credentials.remember === 'true') {
            localStorage.setItem('remember', 'true');
            localStorage.setItem('username', credentials.username);
            localStorage.setItem('password', credentials.password);
          }
        })
        .catch((err) => {
          notification('No se ha podido iniciar sesión', 'error');
        })
        .finally(() => loadingRef.current.instance.option('visible', false));
    },
    [set]
  );

  useEffect(() => {
    const remember = localStorage.getItem('remember') === 'true';
    if (!remember) return;

    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');

    login({ username, password });
  }, [login]);

  return (
    <>
      <LoadPanel ref={loadingRef} />
      <FormWrapper onSubmit={handleSubmit}>
        <AppLogo />
        <Form
          labelMode="floating"
          items={[
            { dataField: 'username', label: { text: 'Usuario' }, validationRules: [{ type: 'required' }] },
            {
              dataField: 'password',
              label: { text: 'Contraseña' },
              validationRules: [{ type: 'required' }],
              editorOptions: { mode: 'password' },
            },
            { dataField: 'remember', label: { text: 'Mantener la sesión iniciada' }, editorType: 'dxSwitch' },
            {
              itemType: 'button',
              horizontalAlignment: 'center',
              buttonOptions: { text: 'Iniciar sesión', icon: 'user', type: 'default', useSubmitBehavior: true },
            },
          ]}
        />
      </FormWrapper>
      {profile ? <DataLoader /> : null}
    </>
  );
};

export default LoginPage;

const FormWrapper = styled.form`
  gap: 8px;
  padding: 16px 32px;
  max-width: 320px;
  margin: 0 auto;
`;
